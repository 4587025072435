<template>
    <div class="lends">
        <p>借出/归还记录</p>
        <div class="handleBar"
            style="width: 94%;padding:5px;text-align:left;margin: 0 auto;margin-top: 20px;border-radius: 5px;background-color: white;">
            <el-button style="margin-left: 2px;" @click="execu" type="primary" plain>批量归还</el-button>
        </div>
        <el-table :key="itemKey" :data="tableData" border ref="multipleTable" @selection-change="selsChange"
            style="width: 95%;margin: 0 auto;margin-top: 5px;border-radius: 5px;" height="500px">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column prop="id" label="编号" width="50px" align="center">
            </el-table-column>
            <el-table-column prop="name" label="名称" style="width:15%" align="center">
            </el-table-column>
            <el-table-column prop="guige" label="规格" style="width:15%" align="center">
            </el-table-column>
            <el-table-column prop="xinghao" label="型号" style="width:15%" align="center">
            </el-table-column>
            <el-table-column prop="num" label="申请数量" width="85px" align="center">
            </el-table-column>
            <el-table-column prop="applytime" label="申请时间" width="160px" align="center">
            </el-table-column>
            <el-table-column prop="lendtime" label="借出时间" width="160px">
            </el-table-column>
            <el-table-column prop="returntime" label="归还时间" width="160px" align="center">
            </el-table-column>
            <el-table-column prop="state" label="状态" width="80px" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.state == '4'">已归还</el-tag>
                    <el-tag type="danger" v-if="scope.row.state == '2'">未归还</el-tag>
                    <el-tag type="warning" v-if="scope.row.state == '1'">申请中</el-tag>
                    <el-tag v-if="scope.row.state == '3'">归还中</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" style="width:10%;" align="center">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.state == '2'" @click="handleClick(scope.row)" type="text"
                        size="small">点击归还</el-button>
                    <el-button v-if="scope.row.state == '3'" @click="handleClick(scope.row)" type="text"
                        size="small">取消归还</el-button>
                </template>

            </el-table-column>

        </el-table>


    </div>
</template>

<style scoped>
.lends {
    width: 95%;
    min-width: 50%;
    min-height: 630px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: inset;
    color: white;
    padding: 10px;
    background-color: #6f846450;
}
</style>

<script>
export default {
    methods: {
        say(msg) {
            this.$message({
                message: msg,
                type: 'success'
            });
        },
        selsChange(selection)//selection是一个数组，包含了被选中的所有item
        {
            this.selected = selection;
            var s = selection.map((item) => item.field1);
        },
        handleClick(row) {

            this.$confirm('确定' + (row.state == "3" ? "取消" : "") + '归还此元器件吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                for (var i = 0; i < this.tableData.length; i++) {
                    if (this.tableData[i].id == row.id) {
                        if (row.state == "3") {//取消归还
                            this.$axios.get(this.host + "/cancelBack", { params: { id: row.id } }).then(response => {
                                if (response.data == 1) {
                                    // this.tableData[i].state= "2";
                                    row.state = '2';
                                    this.$message({
                                        type: 'success',
                                        message: '取消归还成功!'
                                    });
                                    this.init();
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: '取消归还失败!'
                                    });
                                }
                            });
                        } else if (row.state == "2") {//未归还，申请归还
                            this.$axios.get(this.host + "/returnBack", { params: { id: row.id } }).then(response => {
                                if (response.data == 1) {
                                    // this.tableData[i].state = "3";
                                    row.state = '3';
                                    this.$message({
                                        type: 'success',
                                        message: '申请归还成功，待管理员审核!'
                                    });
                                    this.init();
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: '申请归还失败!'
                                    });
                                }
                            });

                        }
                        

                    }
                }
                this.itemKey = Math.random();
                
                
            }).catch(() => {
                //取消删除
            });
        },
        handleChange(value) {
            // this.$message({
            //     message: value,
            //     type: 'success'
            // });
        },
        execu() {
            if (this.selected.length == 0) {
                this.$message({
                    type: 'warning',
                    message: "请勾选要归还的物品"
                });
            } else
                this.$confirm('确定批量归还选中项吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let need = 0, noNeed = 0, all = this.selected.length;
                    let dat = [];
                    for (var j = 0; j < this.selected.length; j++) {
                        if (this.selected[j].state == "2") {
                            need++;
                        }
                    }
                    noNeed = this.selected.length - need;
                    for (var i = 0; i < this.tableData.length; i++) {
                        for (var j = 0; j < this.selected.length; j++) {
                            if (this.tableData[i].id == this.selected[j].id) {

                                if (this.selected[j].state == "2") {
                                    // this.say("i:" + i + ",j:" + j);
                                    this.tableData[i].state = "3";
                                    dat.push(this.tableData[i].id);
                                }

                            }
                        }
                    }
                    this.$axios.post(this.host + "/updateLendsByIds", { id: dat }).then(response => {
                        if (response.data == 1) {

                            this.itemKey = Math.random();
                            this.$message({
                                type: 'success',
                                message: this.value + '选中' + all + '个，' + need + '已申请归还，待管理员审核!'
                            });
                            this.selected = [];
                            this.init();
                        } else {
                            this.$message({
                                type: 'error',
                                message: '批量归还失败!'
                            });
                        }
                    });
                    
                }).catch(() => {
                    //取消执行
                });
        },
        init() {
            this.$axios.get(this.host + "/getLends", { params: { username: 'jack' } }).then(response => {
                console.log("LendsView.tableData:");
                console.log(response.data);
                this.tableData = response.data;
                this.itemKey = Math.random();
            });
        }
    },
    mounted() {
        window.history.forward(1);
        this.init();
    },
    data() {
        return {
            itemKey: 0,
            host: "http://lend.dc406.cn:8085",
            selected: [],
            tableData: [],

            options: [{
                value: '删除',
                label: '删除'
            }, {
                value: '申请',
                label: '申请'
            }],
            value: '',
        }
    }
}
</script>