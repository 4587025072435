<template>
    <div class="base">
        <!--大标题-->
        <div class="bigTitle">实 验 室 物 品 管 理 系 统</div>
        <!-- 注册登录界面 -->
        <div class="loginAndRegist">
            <!--登录表单-->
            <div class="loginArea">
                <!-- 标语 -->
                <div v-show="isShow" class="title">
                    登&nbsp;&nbsp;&nbsp;录
                </div>
                <!-- 密码框和用户名框 -->
                <div v-show="isShow" class="pwdArea">
                    <div class="std">
                        <div class="left">用&nbsp;户&nbsp;名</div>
                        <div class="right"><el-input v-model="logUsername" placeholder="请输入用户名"></el-input></div>
                    </div>
                    <div class="std">
                        <div class="left">密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码</div>
                        <div class="right"><el-input placeholder="请输入密码" v-model="logPassword" show-password></el-input>
                        </div>
                    </div>
                </div>
                <!-- 登录按钮 -->
                <div v-show="isShow" class="btnArea">
                    <el-button type="success" round @click="UserLogin">登录</el-button>
                </div>
            </div>
            <!-- 注册表单 -->
            <div class="registArea">
                <!--  注册表头-->
                <div v-show="!isShow" class="rigestTitle">
                    注&nbsp;&nbsp;&nbsp;册
                </div>
                <!--  注册表单-->
                <div v-show="!isShow" class="registForm">
                    <div id="username" class="std2">
                        <div class="left">用&nbsp;&nbsp;&nbsp;户&nbsp;&nbsp;&nbsp;名:&nbsp;</div>
                        <div class="right"><el-input placeholder="请输入用户名" v-model="regUsername"></el-input></div>
                    </div>
                    <div id="invitationCode" class="std2">
                        <div class="left">邀&nbsp;&nbsp;&nbsp;请&nbsp;&nbsp;&nbsp;码:&nbsp;</div>
                        <div class="right"><el-input type="text" placeholder="请输入邀请码" v-model="code" maxlength="9"
                                show-word-limit></el-input></div>
                    </div>
                    <div id="password" class="std2">
                        <div class="left">密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:&nbsp;</div>
                        <div class="right"><el-input placeholder="请输入密码" v-model="regPassword" show-password></el-input>
                        </div>
                    </div>
                    <div id="confirmPassword" class="std2">
                        <div class="left">确&nbsp;认&nbsp;密&nbsp;码:&nbsp;</div>
                        <div class="right"><el-input placeholder="请再次输入密码" v-model="regRePassword" show-password></el-input>
                        </div>
                    </div>
                </div>
                <!-- 注册按钮-->
                <div v-show="!isShow" class="btnArea">
                    <el-button type="success" round @click="userRegister">注册</el-button>
                </div>
            </div>
            <!-- 信息展示界面 -->
            <div id="aaa" class="showInfo" :style="{
                borderTopRightRadius: styleObj.bordertoprightradius,
                borderBottomRightRadius: styleObj.borderbottomrightradius,
                borderTopLeftRadius: styleObj.bordertopleftradius,
                borderBottomLeftRadius: styleObj.borderbottomleftradius,
                right: styleObj.rightDis
            }" ref="showInfoView">
                <!-- 没有用户输入用户名或者找不到用户名的时候 -->
                <div v-show="isShow"
                    style="display: flex;flex-direction: column;align-items: center;justify-content: center;width: 100%;height: 100%">
                    <!-- 欢迎语 -->
                    <div class="title"
                        style="flex: 2;display: flex;align-items: center;font-size: 22px;color: #257B5E;font-weight: bold;border-bottom: transparent;">
                        欢 迎 登 录
                    </div>
                    <!-- 欢迎图片 -->
                    <div style="flex: 2">
                        <el-button type="success" style="background-color:#257B5E;border: 1px solid #ffffff;" round
                            @click="changeToRegiest">还没有账户？点击注册</el-button>
                    </div>
                </div>
                <!-- 用户注册的时候展示信息 -->
                <div v-show="!isShow"
                    style="display: flex;flex-direction: column;align-items: center;justify-content: center;width: 100%;height: 100%">
                    <!-- 欢迎语 -->
                    <div style="flex: 2;display: flex;align-items: center;font-size: 22px;color: #FFFFFF;font-weight: bold">
                        欢&nbsp; 迎&nbsp; 注&nbsp; 册
                    </div>
                    <!-- 欢迎图片 -->
                    <div style="flex: 2">
                        <el-button type="success" style="background-color:#257B5E;border: 1px solid #ffffff;" round
                            @click="changeToLogin">已有账户？点击登录</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div id="copyright">
            Copyright © 2023 China West Normal University Science building C406. All Rights Rerserved.
            <div style="width:300px;margin:0 auto;">
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51130202000417"
                    style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img
                        src="http://www.dc406.cn/upload/20230212/备案图标.png" style="float:left;" />
                    <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color: white; ">川公网安备
                        51130202000417号
                    </p>
                </a>
            </div>
        </div>
        <div class="clock">
            <div id="time">{{ time }}</div>
            <div id="date">{{ date }}</div>
        </div>
        <!-- CSS背景1 -->
        <div class="square">
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
        <!-- CSS背景2 -->
        <div class="circle">
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>

    </div>
</template>

<script>

// import global_ from "../assets/global/Global.vue"
export default {
    name: 'Login',
    el: 'base',
    data() {
        return {
            // 登录
            logUsername: "",
            logPassword: "",
            // 注册
            regUsername: "",
            code: "",
            regPassword: "",
            regRePassword: "",
            // 时钟
            time: "",
            date: "",
            // 登录与注册界面切换效果
            styleObj: {
                bordertoprightradius: '15px',
                borderbottomrightradius: '15px',
                bordertopleftradius: '0px',
                borderbottomleftradius: '0px',
                rightDis: '0px'
            },
            isShow: true,
            host: "http://lend.dc406.cn:8085"
        }
    },
    methods: {
        changeToRegiest() {
            this.styleObj.bordertoprightradius = '0px'
            this.styleObj.borderbottomrightradius = '0px'
            this.styleObj.bordertopleftradius = '15px'
            this.styleObj.borderbottomleftradius = '15px'
            this.styleObj.rightDis = '50%'
            this.isShow = !this.isShow // 消失
        },
        changeToLogin() {
            this.styleObj.bordertoprightradius = '15px'
            this.styleObj.borderbottomrightradius = '15px'
            this.styleObj.bordertopleftradius = '0px'
            this.styleObj.borderbottomleftradius = '0px'
            this.styleObj.rightDis = '0px'
            this.isShow = !this.isShow // 消失
        },
        UserLogin() {
            if (this.logUsername == "") {
                this.$message({
                    type: "error",
                    message: "请输入用户名"
                });
                return;
            }else if (this.logPassword == "") {
                this.$message({
                    type: "error",
                    message: "请输入密码"
                });
                return;
            }
            this.$axios.post(this.host + "/login", { username: this.logUsername, password: this.logPassword })
                .then(response => {
                    switch (response.data) {
                        case 1://用户名不存在
                            this.$message({
                                type: "error",
                                message: "用户名不存在"
                            });
                            break;

                        case 2://登录成功

                            this.$message({
                                type: "success",
                                message: "登录成功"
                            });
                            setTimeout(function () {
                                location.href = "/items";

                            }, 500);

                            break;

                        case 3://密码错误
                            this.$message({
                                type: "error",
                                message: "密码错误"
                            });
                            break;
                        case 999:
                            this.$message({
                                type: "success",
                                message: "欢迎你，管理员"
                            });
                            setTimeout(function () {
                                location.href = "/admin";
                            }, 500);

                            break;
                    }
                });
        },
        judgeReg()
        {
            if (this.regUsername == "") {
                this.$message({
                    type: "error",
                    message: "请输入用户名"
                });
                return false;
            }else if (!this.regUsername.match(/^[a-zA-Z]+[0-9]*/)) {
                this.$message({
                    type: "error",
                    message: "用户名只能是字母+数字或纯字母形式"
                });
                return false;
            }else if (this.regUsername.length>12) {
                this.$message({
                    type: "error",
                    message: "用户名长度不得大于12位"
                });
                return false;
            }else if (this.code.length!=9) {
                this.$message({
                    type: "error",
                    message: "邀请码长度不够"
                });
                return false;
            }else if (this.regPassword == "") {
                this.$message({
                    type: "error",
                    message: "请输入密码"
                });
                return false;
            }else if (!this.regPassword.match(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/)) {
                this.$message({
                    type: "error",
                    message: "密码必须包含字母和数字，且在6~18位之间"
                });
                return false;
            }else if (this.regPassword!=this.regRePassword) {
                this.$message({
                    type: "error",
                    message: "两次输入密码不一致"
                });
                return false;
            }
            return true;
        },
        userRegister() {
            if(!this.judgeReg())
            {
                return;
            }
            this.$axios.post(this.host + "/register", { username: this.regUsername, password: this.regPassword, code: this.code })
                .then(response => {
                    switch (response.data) {
                        case 0://用户名重复
                            this.$message({
                                type: "error",
                                message: "注册失败！用户名重复"
                            });
                            break;

                        case 1://注册成功

                            this.$message({
                                type: "success",
                                message: "注册成功"
                            });
                            this.changeToLogin();

                            break;

                        case 2:
                            this.$message({
                                type: "error",
                                message: "邀请码错误"
                            });
                            break;
                        case 3://未知错误
                            this.$message({
                                type: "error",
                                message: "未知错误"
                            });
                            break;

                    }
                });
        },
        update_clock: function (e) {
            var d = new Date();
            var year = d.getFullYear();
            if (year < 10) {
                year = "0" + year;
            }
            var mon = d.getMonth();
            if (mon < 10) {
                mon = "0" + mon;
            }
            var day = d.getDate();
            if (day < 10) {
                day = "0" + day;
            }
            var hour = d.getHours();
            if (hour < 10) {
                hour = "0" + hour;
            }
            var minute = d.getMinutes();
            if (minute < 10) {
                minute = "0" + minute;
            }
            var second = d.getSeconds();
            if (second < 10) {
                second = "0" + second;
            }
            this.time = hour + ":" + minute + ":" + second;
            this.date = year + "/" + mon + "/" + day;
        }
    },
    mounted() {

        this.$axios.get(this.host + "/getLoginInfo")
            .then(response => {
                window.aaa = response.data;
                if (response.data.username != null) {
                    // alert("已登录");
                    if (response.data.auth == "1") {
                        location.href = "/admin";
                    } else {
                        location.href = "/items"
                    }

                }
            });
        window.history.forward(1);
        this.$nextTick(() => {
            setInterval(this.update_clock, 1000);
        })
    }
}   
</script>
<style>
@import url("../assets/css/login.css");
</style>