import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import axios from 'axios'

Vue.use(ElementUI);
Vue.config.productionTip = false
axios.defaults.baseURL = 'http://lend.dc406.cn:8085'
axios.defaults.withCredentials=true;//让ajax携带cookie
Vue.prototype.$axios=axios;


new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
