<template>
    <div class="apply">
        <p>以下是您即将申请借出的元器件</p>
        <div class="handleBar"
            style="width: 94%;padding:5px;text-align:left;margin: 0 auto;margin-top: 20px;border-radius: 5px;background-color: white;">
            <el-select style="margin-left: 1px;" v-model="value" placeholder="请选择操作">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <el-button style="margin-left: 5px;" @click="execu" type="primary" plain>执行</el-button>
        </div>
        <el-table :key="itemKey" :data="tableData" border ref="multipleTable" @selection-change="selsChange"
            style="width: 95%;margin: 0 auto;margin-top: 5px;border-radius: 5px;" height="500px">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column prop="id" label="编号" width="50px" align="center">
            </el-table-column>
            <el-table-column prop="name" label="名称" style="width:15%" align="center">
            </el-table-column>
            <el-table-column prop="guige" label="规格" style="width:15%" align="center">
            </el-table-column>
            <el-table-column prop="xinghao" label="型号" style="width:15%" align="center">
            </el-table-column>
            <el-table-column prop="amount" label="剩余数量" style="width:15%" align="center">
            </el-table-column>
            <el-table-column prop="num" label="申请数量" width="155px" align="center">

                <template  slot-scope="scope">
                    <el-input-number v-model="scope.row.num" size="mini" @change="handleChange(scope.row)" :min="1"
                        :max="scope.row.amount" style="min-width: 100px;"></el-input-number>
                </template>

            </el-table-column>
            <el-table-column label="操作" width="80px" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small" style="color: red;">删除</el-button>
                </template>

            </el-table-column>

        </el-table>


    </div>
</template>

<style scoped>
.apply {
    width: 95%;
    min-width: 50%;
    min-height: 630px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: inset;
    color: white;
    padding: 10px;
    background-color: #6f846450;
}

</style>

<script>

export default {
    methods: {
        say(msg) {
            this.$message({
                message: msg,
                type: 'success'
            });
        },
        selsChange(selection)//selection是一个数组，包含了被选中的所有item
        {
            this.selected = selection;
            var s = selection.map((item) => item.field1);
            // this.say("" + JSON.stringify(selection));
            // this.say(selection[0].id)
        },
        //获取选中的行数据
        handleClick(row) {
            this.$confirm('确定删除此元器件吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.host + "/deleteAppliesByIds", { id: [row.id] }).then(response => {
                    if (response.data == 1) {
                        for (var i = 0; i < this.tableData.length; i++) {
                            var item = this.tableData[i];
                            if (item.id == row.id) {

                                this.tableData.splice(i, 1);
                                // this.say("len="+this.tableData.length);

                                this.itemKey = Math.random();
                                break;
                            }
                        }
                        sessionStorage.setItem("applies", JSON.stringify(this.tableData));
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });

                    } else {
                        this.$message({
                            type: 'error',
                            message: '删除失败!'
                        });
                    }
                });

            }).catch(() => {
                //取消删除
            });
        },
        handleChange(row) {
            clearTimeout(this.timeout["_" + row.id]);
            let f = this.postApplyNum;
            //注意：setTimeout只能传入函数头，而不能直接传入函数的调用
            this.timeout["_" + row.id] = setTimeout(function () { f(row); }, 1000);
        },
        execu() {
            if (this.selected.length == 0) {
                this.$message({
                    type: 'warning',
                    message: '请选择元器件'
                });
                return;
            }
            if (this.value == '') {
                this.$message({
                    type: 'warning',
                    message: "请选择操作"
                });
            } else
                this.$confirm('确定' + this.value + '选中项吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {

                    if (this.value == "删除") {
                        let pdata=[];
                        for(let i=0;i<this.selected.length;i++)
                        {
                            pdata.push(this.selected[i].id);
                        }
                        console.log("pdata:");
                        console.log(pdata);
                        this.$axios.post(this.host + "/deleteAppliesByIds", { id: pdata }).then(response => {

                            if (response.data == 1) {
                                // this.say("del" + JSON.stringify(this.selected));
                                for (var i = 0; i < this.tableData.length; i++) {
                                    for (var j = 0; j < this.selected.length; j++) {
                                        if (this.tableData[i].id == this.selected[j].id) {
                                            // this.say("i:" + i + ",j:" + j);
                                            this.tableData.splice(i, 1);
                                        }
                                    }
                                }
                                // this.say("del" + JSON.stringify(this.selected));
                                console.log('ApplyView.execu().applies:');
                                console.log(this.tableData);
                                sessionStorage.setItem("applies",JSON.stringify(this.tableData));
                                this.itemKey = Math.random();
                                this.$message({
                                    type: 'success',
                                    message: this.value + '成功!'
                                });
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: this.value + '失败!'
                                });

                            }

                        });

                    } else {
                        //向服务器发送selected
                        this.$axios.post(this.host+"/applyItemsById",this.selected).then(response=>{
                            if(response.data==1)
                            {
                                sessionStorage.setItem("applies",null);
                                this.init();
                                this.$message({
                                    type: 'success',
                                    message: this.value + '成功!'
                                });
                            }else{
                                this.$message({
                                    type: 'success',
                                    message: this.value + '失败!'
                                });
                            }
                        });


                        // this.say("del" + JSON.stringify(this.selected));
                        // for (var i = 0; i < this.tableData.length; i++) {
                        //     for (var j = 0; j < this.selected.length; j++) {
                        //         if (this.tableData[i].id == this.selected[j].id) {
                        //             // this.say("i:" + i + ",j:" + j);
                        //             this.tableData.splice(i, 1);
                        //             this.selected.splice(j, 1);
                        //         }
                        //     }
                        // }
                        // // this.say("del" + JSON.stringify(this.selected));
                        // this.itemKey = Math.random();

                        // this.$message({
                        //     type: 'success',
                        //     message: this.value + '成功!'
                        // });
                    }


                }).catch(() => {
                    //取消执行
                });
        },
        init() {
            let dat = JSON.parse(sessionStorage.getItem("applies"));
            if (dat == null) {
                this.$axios.get(this.host + "/getApplyList", {
                    params: {
                        username: 'jack'
                    }
                }).then(response => {
                    this.tableData = response.data;
                    console.log("applies");
                    console.log(response.data);
                    this.itemKey = Math.random();
                    //存
                    sessionStorage.setItem("applies", JSON.stringify(this.tableData));
                });
            } else {
                // this.say("ss");
                this.tableData = dat;
                this.itemKey = Math.random();
            }

        },
        postApplyNum(row) {
            // this.say("提交");
            // console.log("提交");
            this.$axios.post(this.host + "/refreshApplyNum", { num: row.num, id: row.id })
                .then(response => {
                    let t = response.data;
                    if (t == 0) {
                        this.$message({
                            type: "error",
                            message: "更新失败"
                        });
                    } else {
                        sessionStorage.setItem("applies", JSON.stringify(this.tableData));
                    }
                });
        }
    },
    mounted() {
        window.history.forward(1);
        this.init();

    },
    data() {
        return {
            itemKey: 0,
            selected: [],
            tableData: [],
            options: [{
                value: '删除',
                label: '删除'
            }, {
                value: '申请',
                label: '申请'
            }],
            value: '',
            host: 'http://lend.dc406.cn:8085',
            timeout: {},
        }
    }
}
</script>