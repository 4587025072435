<template>
  <div id="app">
    <!-- 背景 -->
    <canvas id="mom" v-show="isShow()"
      style="background:#111;display: block; position: fixed; margin: 0px; padding: 0px; border: 0px; outline: 0px; left: 0px; top: 0px; width: 100%; height: 100%; z-index: -1;"></canvas>
    <nav v-show="isShow()">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1"><router-link to="/items">物品清单</router-link></el-menu-item>
        <el-menu-item index="2"><router-link to="/apply">申请清单</router-link></el-menu-item>
        <el-menu-item index="3"><router-link to="/lends">借还详情</router-link></el-menu-item>
        <el-menu-item index="4"><router-link to="/about">关于</router-link></el-menu-item>
        <!-- <el-submenu index="4">
          <template slot="title">关于</template>
          <el-menu-item index="4-1"><router-link to="/developers">开发人员</router-link></el-menu-item>
          <el-menu-item index="4-2"><router-link to="/sysIntroduction">系统简介</router-link></el-menu-item>
        </el-submenu> -->
        <el-menu-item style="float:right;border: none;" index="5">
          <a style="text-decoration: none;" href="#" @click="logout()">退出登录</a>
        </el-menu-item>
      </el-menu>
      <div class="line" style="width: 100%;"></div>
    </nav>
    <router-view />

    <div style="width:300px;margin:0 auto;margin-top: 30px;">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51130202000417"
        style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img
          src="http://www.dc406.cn/upload/20230212/备案图标.png" style="float:left;" />
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color: purple; ">川公网安备 51130202000417号
        </p>
      </a>
    </div>
  </div>
</template>

<style>
.el-menu a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: block;
  color: black;
}

body {
  margin: 0;
}

nav>ul {
  margin: 0 auto !important;

}

nav * {
  background-color: transparent !important;
  color: #FFFFFF !important;
}

nav {
  background: #6f846450;
}

nav .el-menu.el-menu--horizontal {
  border-bottom: none;
}

nav .el-menu-item {
  padding: 0;
  width: 100px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<script>
// import global_ from "./assets/global/Global.vue"
export default {
  data() {
    return {
      host: "http://lend.dc406.cn:8085",
      activeIndex: "1",
      path: "/",
      paths: {
        "/items": "1",
        "/apply": "2",
        "/lends": "3",
        "/about": "4",
        // "/developers": "4-1",
        // "/sysIntroduction": "4-2"
      }
    };
  },
  mounted() {
    this.path = this.$route.path;
    // window.mData=global_.path_;//此操作可将path映射到控制台
    let acin = sessionStorage.getItem("activeIndex");
    if (acin != null) {
      this.activeIndex = acin;
      // alert(acin);
    } else {
      sessionStorage.setItem("activeIndex", this.paths[this.path]);
      this.activeIndex = this.paths[this.path];
    }

    if (this.isShow()) {
      this.drawBackground();
    }
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
    }
  },
  methods: {
    isShow() {
      if (this.path == '/' || this.path.indexOf("admin") != -1) {
        return false;
      }

      return true;

    },
    handleSelect() {
      // alert(this.activeIndex);
      sessionStorage.setItem("activeIndex", this.paths[this.path]);
    },
    logout() {
      this.$axios.get(this.host + "/logout").then(resp => {
        this.$message({
          type: "success",
          message: "退出登录成功"
        });
        setTimeout(function () {
          location.href = "/";
          sessionStorage.setItem("activeIndex", 1);
        }, 500);
      });
    },
    drawBackground() {
      window.onload = function () {
        //获取画布对象
        var canvas = document.getElementById("mom");
        //获取画布的上下文
        //getContext() 方法返回一个用于在画布上绘图的环境。
        var context = canvas.getContext("2d");
        //获取浏览器屏幕的宽度和高度
        var W = window.innerWidth;
        var H = window.innerHeight;
        //设置canvas的宽度和高度
        canvas.width = W;
        canvas.height = H;
        //每个文字的字体大小
        var fontSize = 12;
        //计算列
        var colunms = Math.floor(W / fontSize);
        //记录每列文字的y轴坐标
        var drops = [];
        //给每一个文字初始化一个起始点的位置
        //计算每一个文字所谓坐标 存储y轴的坐标 
        for (var i = 0; i < colunms; i++) {
          drops.push(0);
        }
        //---------------------------------------
        //运动的文字
        var str = "01";
        //---------------------------------------


        //4:fillText(str,x,y);原理就是去更改y的坐标位置
        //绘画的函数
        function draw() {
          context.fillStyle = "rgba(0,0,0,0.05)";
          //fillRect() 方法绘制“已填色”的矩形。默认的填充颜色是黑色。
          context.fillRect(0, 0, W, H);
          //给字体设置样式
          context.font = "700 " + fontSize + "px 微软雅黑";
          //给字体添加颜色
          context.fillStyle = "#00cc33";//可以rgb,hsl, 标准色，十六进制颜色
          //写入画布中
          for (var i = 0; i < colunms; i++) {
            var index = Math.floor(Math.random() * str.length);//设置文字出发时间随机 Math.floor(Math.random()*str.length)让数组里面的文字索引随机出现 
            var x = i * fontSize;
            var y = drops[i] * fontSize;//也让y轴方向也向下掉一个文字的距离
            context.fillText(str[index], x, y);
            // //如果要改变时间，肯定就是改变每次他的起点
            if (y >= canvas.height && Math.random() > 0.99) {
              drops[i] = 0;
            }
            drops[i]++;//让数组里面的值每次加一，用于上面的y轴下掉 
          }
        };
        //随机颜色
        function randColor() {
          var r = Math.floor(Math.random() * 256);
          var g = Math.floor(Math.random() * 256);
          var b = Math.floor(Math.random() * 256);
          return "rgb(" + r + "," + g + "," + b + ")";
        }
        draw();
        setInterval(draw, 20);
      };
    }
  }
}
</script>
