import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import ItemsView from '../views/ItemsView.vue'
import ApplyView from '../views/ApplyView.vue'
import LendsView from '../views/LendsView.vue'
import LoginView from '../views/LoginView.vue'
import AboutView from '../views/AboutView.vue'


Vue.use(VueRouter);

const routes = [
  {
    path:'/',
    name:'loginView',
    component:LoginView
  },
  {
    path: '/items',
    name: 'items',
    component: ItemsView
  },
  {
    path:'/apply',
    name:'apply',
    component: ApplyView
  },
  {
    path:'/lends',
    name:'lends',
    component: LendsView
  },
  {
    path:'/about',
    name:'about',
    component: AboutView
  },

  {
    path: '/admin/AdminMain',
    component: () => import('../views/admin/AdminMain.vue'),
    children: [
        {
        path: "/admin",
        component: () => import('../views/admin/ItemsMgr.vue')
      },
      {
        path: "/admin/UserList",
        component: () => import('../views/admin/UserList.vue')
      },
      {
        path: "/admin/LendRecord",
        component: () => import('../views/admin/LendRecord.vue')
      }, {
        path: "/admin/ApplyMsg",
        component: () => import('../views/admin/ApplyMsg.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
