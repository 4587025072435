<template>
    <div class="items">
        <p>实验室元器件配件清单</p>
        <div class="search"
            style="width: 94%;padding:5px;text-align:left;margin: 0 auto;margin-top: 20px;border-radius: 5px;background-color: white;">

            <el-cascader placeholder="例：稳压二极管 / 5V / 直插" v-model="searchWords" :options="options" filterable></el-cascader>
            <el-button type="primary" icon="el-icon-search" @click="search()" style="margin-left: 5px;" plain>搜索</el-button>
            <el-button type="danger" icon="el-icon-search" @click="disSearch()" style="margin-left: 5px;"
                plain>取消搜索</el-button>
        </div>
        <el-table :key="itemKey" :data="tableData" border v-loading="loading"
            element-loading-background="rgba(255, 255, 255, .5)" element-loading-text="加载中，请稍后..."
            element-loading-spinner="el-icon-loading" style="width: 95%;margin: 0 auto;margin-top: 5px;border-radius: 5px;"
            height="480px">
            <el-table-column prop="id" label="ID" width="50px" align="center">
            </el-table-column>
            <el-table-column prop="name" label="编号" style="width:15%" align="center">
            </el-table-column>
            <el-table-column prop="guige" label="规格" style="width:15%" align="center">
            </el-table-column>
            <el-table-column prop="xinghao" label="型号" style="width:15%" align="center">
            </el-table-column>
            <el-table-column prop="amount" label="库存" style="width:15%" align="center">
            </el-table-column>
            <el-table-column prop="beizhu" label="备注" style="width:15%" align="center">
            </el-table-column>
            <el-table-column label="操作" width="250px" align="center">
                <template slot-scope="scope">
                    <el-button @click="lookDetails(scope.row)" type="success" size="small" plain>器件介绍</el-button>
                    <el-button @click="handleClick(scope.row)" type="primary" size="small" plain>加入申请表</el-button>
                </template>

            </el-table-column>

        </el-table>
        <div
            style="width: 94%;padding:5px;text-align:center;margin: 0 auto;margin-top: 5px;border-radius: 5px;background-color: white;">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="q.pagenum"
                :page-sizes="[10, 20, 30, 40]" :page-size="q.pagesize" layout="total, sizes, prev, pager, next, jumper"
                :total="q.total">
            </el-pagination>
        </div>

        <el-dialog :title="rowNow.name + '-' + rowNow.guige + '-' + rowNow.xinghao" :visible.sync="dialogVisible"
            width="50%">
            <el-image style="line-height:270px;" :src="host2 + '/img/items/' + editObj.name + '.jpg?t='+(new Date().getTime())"></el-image>
            <div id="item_detail">
                <el-descriptions class="margin-top" :column="1" border>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-postcard" style="color: blue;"></i>
                            <span style="color: blue;font-weight: bold;">器件名称</span>
                        </template>
                        <el-tag size="small">
                            {{ editObj.name }}
                        </el-tag>

                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-wallet" style="color: blue;"></i>
                            <span style="color: blue;font-weight: bold;">大致价格</span>
                            
                        </template>
                        <el-tag size="small" type="warning">
                            <span style="color: red;">{{ editObj.price }}</span></el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-location-outline" style="color: blue;"></i>
                            
                            <span style="color: blue;font-weight: bold;">用途</span>
                        </template>
                        <span style="color: blue;">{{ editObj.usement }}</span>
                        
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets" style="color: blue;"></i>
                            
                            <span style="color: blue;font-weight: bold;">使用方法</span>
                        </template>
                        <span style="color: blue;">{{ editObj.method }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-office-building" style="color: blue;"></i>
                            
                            <span style="color: blue;font-weight: bold;">注意事项</span>
                        </template>
                        <span style="color: blue;">{{ editObj.attention }}</span>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </el-dialog>
    </div>
</template>

<style scoped>
.items {
    width: 95%;
    min-width: 50%;
    min-height: 630px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: inset;
    color: white;
    padding: 10px;
    background-color: #6f846450;
}
</style>

<script>

export default {
    methods: {
        handleClick(row) {
            
            let dt = {
                username: 'jack',
                itemid: row.id,
                num: 1,
                state: '0',
            };
            this.$axios.post(this.host + "/addApplication", dt).then(response => {
                if (response.data == 0) {
                    this.$message({
                        message: "请勿重复申请！",
                        type: 'error'
                    });
                } else {
                    sessionStorage.removeItem("applies");
                    this.$message({
                        message: row.name + " " + row.guige + " " + row.xinghao + " 已加入申请表，可在申请表中批量申请借用。",
                        type: 'success'
                    });
                }
            });

        },
        lookDetails(row) {
            this.rowNow = row;
            // this.say(this.rowNow);
            this.$axios.get(this.host + "/getItemDetailByName", {
                params: {
                    name: row.name
                }
            }).then(response => {
                this.editObj = response.data;
                this.dialogVisible = true;
            });

        },
        say(msg) {
            this.$message({
                message: msg,
                type: 'success'
            });
        },
        getArticleListFn() {
            //取
            let dat = JSON.parse(sessionStorage.getItem("items_" + this.q.pagesize + "_" + this.q.pagenum));
            if (dat == null) {
                this.$axios.get(this.host + "/getItemsBetween", {
                    params: {
                        limit: this.q.pagesize,
                        offset: this.q.pagesize * (this.q.pagenum - 1)
                    }
                }).then(response => {
                    this.loading = false;
                    this.tableData = response.data;
                    //存
                    sessionStorage.setItem("items_"+this.q.pagesize+"_"+this.q.pagenum, JSON.stringify(this.tableData));
                    this.itemKey = Math.random();

                });
            } else {
                // this.say("嘿嘿");
                this.tableData=dat;
                this.loading=false;
                this.itemKey = Math.random();
            }


        },
        disSearch() {
            // alert(this.q.pagenum);
            this.handleCurrentChange(this.q.pagenum);
        },
        search() {
            // this.say(this.searchWords);
            var arr = this.searchWords;
            if (arr.length == 3)//是例如“三极管 / 9013 / TO-93”的形式
            {

                for (var i = 0; i < this.items.length; i++) {
                    var item = this.items[i];
                    if (item.name == arr[0] && item.guige == arr[1] && item.xinghao == arr[2]) {

                        this.itemKey = Math.random();
                        this.tableData = [item];

                        break;
                    }
                }
            } else {
                // this.say("没找到");
            }

        },
        transData() {
            var l1 = {};//去重用

            var dt = this.items;//列表-字典s
            for (var i = 0; i < dt.length; i++) {
                //dt[i]是字典，dt[i].name是字符串，dt[i].name举例：电阻
                l1[dt[i].name] = 1;
            }

            var ll1 = [];
            for (var key1 in l1) {
                var child1 = {};
                child1["value"] = child1["label"] = key1;

                var l2 = {};//去重用
                for (var i = 0; i < dt.length; i++) {
                    //key就是name

                    if (dt[i].name == key1) {
                        l2[dt[i].guige] = 1;
                    }
                }

                var ll2 = [];//在value为key1时对应的children-list
                for (var key2 in l2) {
                    var child2 = {};
                    child2["value"] = child2["label"] = key2;

                    var l3 = {};//去重用
                    for (var i = 0; i < dt.length; i++) {

                        if (dt[i].name == key1 && dt[i].guige == key2) {
                            l3[dt[i].xinghao] = 1;
                        }
                    }
                    var ll3 = [];
                    for (var key3 in l3) {
                        var child3 = {};
                        child3["value"] = child3["label"] = key3;
                        ll3.push(child3);
                    }

                    child2["children"] = ll3;

                    ll2.push(child2);
                }

                child1["children"] = ll2;

                ll1.push(child1);
            }

            this.options = ll1;

            // this.$message({
            //     message: ll1.toString(),
            //     type: 'success'
            // });

        },
        handleSizeChange(size) { // 改变每页数量的事件
            this.q.pagesize = size
            this.q.pagenum = 1//当改变了每页显示数量时，把默认页更改为1，不然有bug
            this.getArticleListFn()//调用或列表数据方法
        },
        handleCurrentChange(nowPage) { // 更改当前页码
            this.q.pagenum = nowPage
            this.getArticleListFn()//调用或列表数据方法
        },
        getItemsSize() {
            this.$axios.get(this.host + "/getItemsSize").then(response => {
                this.q.total = response.data;
            });
        },
        getItemList() {
            //取
            let dat = JSON.parse(sessionStorage.getItem("items"));
            if (dat == null) {
                this.$axios.get(this.host + "/getItems").then(response => {
                    // this.say(response.data);
                    // console.log(response.data);
                    this.items = response.data;
                    //存
                    sessionStorage.setItem("items", JSON.stringify(this.items));

                    //清除某一项
                    // sessionStorage.removeItem("insuranceCode");
                    // //清除所有
                    // sessionStorage.clear();

                    this.transData();
                });
            } else {
                // this.say("哈哈哈");
                this.items = dat;
                this.transData();
            }
        },
    },
    mounted: function () {
        window.Vue = this;
        window.history.forward(1);
        this.getItemsSize();
        this.getArticleListFn();
        this.getItemList();
    },
    data() {
        return {
            dialogVisible: false,
            rowNow: "",
            searchWords: "",
            itemKey: 0,
            options: [],
            tableData: [],
            items: [],
            fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
            editObj: {
                name: "name",
                price: "price",
                usement: "usement",
                method: "method",
                attention: "attention"
            },
            q: { // 查询参数
                pagenum: 1, // 默认第一页数据
                pagesize: 10, // 默认当前页显示数据条数
                total: 10,
            },
            host: "http://lend.dc406.cn:8085",
            host2:"http://lend.dc406.cn",
            loading: true,
        }
    }
}
</script>